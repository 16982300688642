import './AudioPlayer.css';
import React, { useRef, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Slider } from '@mui/material';

function AudioPlayer(props) {
    const { src, type } = props
    const [audioProgress, setAudioProgress] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const audioRef = useRef(null)

      function formatTime(remainingTime) {
        if (remainingTime == null) {
          return "--:--"
        }
        let remainingMinutes = Math.floor(remainingTime / 60)
        let remainingSeconds = Math.floor(remainingTime % 60)
        function zerofill(number, width) {
          let str = number.toString();
          while (str.length < width) {
            str = "0" + str;
          }
          return str;
        }
        return remainingMinutes > 0 ? (zerofill(remainingMinutes, 2) + ":" + zerofill(remainingSeconds, 2)) : remainingSeconds
      }

      function onAudioTimeUpdate() {
        var audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        var current = audio.currentTime;
        var percent = (current / audio.duration) * 100
        if (isNaN(percent)) {
          percent = 0
        }
        setAudioProgress(percent)
    }

    function onAudioDurationChange() {
        var audio = audioRef.current
        if (isNaN(audio.duration) || audio.duration == Infinity) {
          audio.currentTime = 1e101
          return
        }
        setDuration(formatTime(audio.duration))
    }

    function onAudioEnded() {
        setIsPlaying(false)
    }

    async function onAudioPausePlay() {
        var audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        if (audio.currentTime === audio.duration) {
            audio.currentTime = 0
        }
        if (isPlaying) {
            await audio.pause()
        } else {
            await audio.play()
        }
        setIsPlaying(!isPlaying)
    }

    function onSliderClick(event) {
        let audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        audio.currentTime = audio.duration * event.target.value * 0.01
    }
    return <div>
        <div className="test-question-audio">
            <div className="test-question-audio-timeline">
            <div className="test-question-audio-timeline-top">
                <div className="test-question-audio-timeline-description">
                    <div className="test-question-audio-seconds">
                        <AccessTimeIcon sx={{fontSize: '14px', lineHeight: '17px', marginRight: '3px'}} />
                        {duration}s
                    </div>
                </div>
            </div>
            <div className="test-question-audio-timeline-bottom">
                <div className="test-question-audio-timeline-controls">
                    <Slider size="small" value={audioProgress} onChange={onSliderClick} sx={{ padding:'0 0 0 0 !important', height: '4px', '& .MuiSlider-thumb': { color: "#222222" }, '& .MuiSlider-track': { color: "#222222" }, '& .MuiSlider-rail': { color: "#EDEDED" }, '& .MuiSlider-active': { color: "#222222" }}} />
                </div>
            </div>
        </div>
        <div className="test-question-audio-button scale-button ripple-black-color" onClick={onAudioPausePlay}>
            {
                isPlaying ? <PauseIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#222222'}} /> :
                    <PlayIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#222222'}} />
            }
        </div>
        </div>
        <audio ref={audioRef} onTimeUpdate={onAudioTimeUpdate} onDurationChange={onAudioDurationChange} onEnded={onAudioEnded}>
            <source src={src} type={type} />
        </audio>
    </div>
}

export default AudioPlayer;