import './PrivatePolicy.css';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { navigationBarState } from '../../../states/NavigationBar';

function PrivatePolicy() {
    const setNavigationBarState = useSetRecoilState(navigationBarState);

    useEffect(() => {
        setNavigationBarState({
          title: '개인정보처리방침',
          'left-item': { type: 'back' },
        });
      }, []);

    return <div>
        <div className="private-policy-container">
          <div className="private-policy-content">
              <h1>개인정보 보호정책</h1>
              주식회사 케이비티영어교육(이하 "회사")는 정보통신망이용촉진및정보보호등에관한법률(이하 “정보통신망법”) 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며,
              관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
              <br />
              1. 개인 정보 수집 목적 및 이용<br />
              회사는 회원님께서 회사의 서비스를 이용하는데 있어, 원활한 서비스 사용 및 회원님에게 편리하고 유익한 정보를 제공하기 위한 최소한의 정보를 수집합니다.<br />
              <br />
              회원님께서 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며, 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.<br />
              <br />
              2. 개인 정보 수집 항목 및 보유, 이용 기간<br />
              회사는 회원 서비스를 위해 필수적으로 제공받는 회원 정보는 이름, ID, 비밀번호, 모바일 Push ID입니다.<br />
              또한 서비스 이용과정에서 입력하는 데이터(텍스트 입력내용, 음성 녹음 등) 정보가 수집됩니다.
              <br />
              회원님께서 회사에서 제공하는 서비스를 받는 동안 회원님의 개인 정보는 회사에서 계속 보유하며 서비스 제공을 위해 이용하게 됩니다.<br />
              단, 회사에 탈퇴를 요청하거나 표명된 회원 자격 상실 사유에 의해 회원 자격을 제한 및 정지시키는 경우에는 해당 개인의 정보는 재생할 수 없는 기술적 방법에 의해 삭제되며, 어떠한 용도로도 열람 또는 이용될 수 없도록 처리됩니다.<br />
              <br />
              3. 개인 정보 제공 및 공유<br />
              원칙적으로 회사는 회원님의 내부정보를 서비스와 무관한 타 기업, 기관에 절대 공개하지 않습니다. 다만, 회원님이 공개에 동의한 경우 또는 회사의 이용약관을 위배하거나 회사의 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우에는 예외로 합니다.<br />
              <br />
              4. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br />
              1) 회원님 개개인에게 개인화되고 맞춤화된 서비스를 제공하기 위해서 당사는 회원님의 식별할 수 있는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹 사이트를 운영하는데 이용되는 서버가 사용자의 브라우저에게 보내는 조그마한 데이터 꾸러미로 회원님 컴퓨터의 하드디스크에 저장됩니다.<br />
              <br />
              ① 쿠키의 사용 목적<br />
              - 회원의 정보를 식별하고 일정 기간내 서비스 재방문시 지속적인 서비스 제공<br />
              ② 쿠키 설정 거부 방법<br />
              2) 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br />
              단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스의 이용이 어려울 수 있습니다.<br />
              <br />
              3) 모바일 앱(APP) 알림서비스 허용시 맞춤서비스를 제공하기 위해서 당사는 회원님의 모바일 Push ID를 저장하고 사용합니다.<br />
              ① Push ID 사용 목적<br />
              - 회사에서 발행되는 정보와 소식 및 공지 알림서비스 제공<br />
              - 맞춤 교육 알림서비스 제공<br />
              <br />
              5. 기술적, 관리적 대책<br />
              회원님의 정보는 비밀번호에 의해 보호되고 있습니다. 또한 회사의 모든 사용자 정보 획득 요청은 쿠키에 저장되는 JSON Web Token 사용자 자격증명 방식을 사용하여 자원의 권한을 관리하고 있습니다.<br />
              또한 회사에서는 서버 및 네트워크 관련 보안시스템을 갖추고 있습니다. 회사는 개인 정보 취급 직원을 최소화는 물론 각 직원별 회원정보 접근 권한을 달리하고, 수시보안교육을 통해 본 정책의 준수를 강조하고 있습니다. 회사에서는 외부로부터 철저하게 격리시켜 철저한 보안을 유지시키고 있습니다. 회원정보 접근 권한을 구분하는 시스템과 보안교육을 통해 내부 침입에도 대응하고 있습니다.<br />
              <br />
              6. 개인 정보 열람과 정정 및 철회<br />
              회원님은 언제든지 제공된 개인 정보를 열람, 정정, 삭제를 요청하실 수 있습니다. 개인 정보 열람 및 정정, 삭제는 회사 사이트의 서비스 관리자(ex. 학원 관리자)에게 요청하여 정정이 가능합니다. 만일 회원 탈퇴를 통하여 개인 정보 제공에 대한 동의를 철회하고 개인정보를 삭제하려면, 서비스 관리자에게 요청하여 처리할 수 있습니다. <br />
              이 때 회사는 회원 탈퇴의 진위를 확인한 후 지체 없이 필요한 조치를 취하여, 이용자의 개인 정보를 파기하고 어떠한 용도로도 이용되지 않도록 처리합니다. 개인 정보와 관련하여 불만이나 의견이 있으신 분은 회사 고객센터나 회사 개인정보 관리 책임자 메일로 의견을 주시면 접수 즉시 조치하고 처리 결과를 통보해 드리겠습니다.<br />
              <br />
              7. 개인 아이디와 비밀번호 관리<br />
              회원님이 사용하고 계시는 ID와 비밀번호는 원칙적으로 회원님만이 사용하도록 되어 있습니다.<br />
              회사의 고의 또는 과실이 없는 경우에, 회원님의 ID와 비밀번호 도용 또는 기타 타인의 사용으로 의해 발생된 문제에 대하여 회사이 책임지지 않습니다. 어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다.<br />
              <br />
              8. 개인정보관리책임자 및 상담신고<br />
              고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 당사는 개인정보관리책임자를 두고 있습니다. 고객의 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보관리책임자 또는 개인정보관리담당자에게 연락 주시기 바랍니다.<br />
              <br />
              ▣ 개인정보 관리책임자<br />
              ▣ 성명 : 김영국 대표이사<br />
              ▣ 연락처 : 02-929-5114<br />
              ▣ 이메일 : googky1@naver.com<br />
              <br />
              ▣ 개인정보 관리담당자<br />
              ▣ 성명 : 김영국 이사<br />
              ▣ 연락처 : 02-929-5114<br />
              ▣ 이메일 : googky1@naver.com<br />
              <br />
              개인정보침해에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 대검찰청 인터넷범죄 수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.<br />
              <br />
              [개인정보침해신고센타]<br />
              * 전화: 국번없이 118<br />
              * URL: https://privacy.kisa.or.kr/<br />
              <br />
              [대검찰청 인터넷범죄수사센터]<br />
              * 전화: 국번없이 1301<br />
              * URL: https://www.spo.go.kr/<br />
              <br />
              [경찰청 사이버안전국]<br />
              * 전화: 국번없이 182<br />
              * URL: https://cyberbureau.police.go.kr/<br />
              <br />
              <br />
              개인정보처리방침 시행일자 : 2023년 12월 07일
              이 약관은 2023년 12월 07일부터 시행합니다.
              <br />
              <br />
              <br />
          </div>
        </div>
    </div>
}

export default PrivatePolicy;