import './TestTimeRemained.css';
import React, { useEffect, useState, useRef } from 'react';

function TestTimeRemained(props) {
  const [remainTime, setRemainTime] = useState(null)
    const { startTime, currentTime, limitTime, onEnded } = props;
    const intervalRef = useRef();

    useEffect(() => {
      if (startTime == null || currentTime == null || limitTime == null) {
        presentRemainTime(null)
        return
      }
      let progressTime = currentTime
      function getRemainingTime(startTime, limitTime) {
        let elapsedTime = Math.floor((progressTime - startTime) / 1000)
        let remainingTime = limitTime - elapsedTime;
        return remainingTime;
      }
  
      function updateRemainingTime() {
        if (startTime == null || progressTime == null || limitTime == null) {
          return
        }
        progressTime = new Date(progressTime.getTime() + 1000)
        let remainingTime = getRemainingTime(startTime, limitTime)
        if (remainingTime < 0) {
          handleEnded()
          return
        }
        setRemainTime(remainingTime)
      }
      
      intervalRef.current = setInterval(updateRemainingTime, [1000])
      
      /// 초기값 셋팅
      let remainingTime = getRemainingTime(startTime, limitTime)
      if (remainingTime >= 0) {
        setRemainTime(remainingTime)
      }
      return () => {
        clearInterval(intervalRef.current)
      }
    }, [startTime, currentTime, limitTime])

    function handleEnded() {
      clearInterval(intervalRef.current);
      onEnded()
    }

    function presentRemainTime(remainingTime) {
      if (remainingTime === null) {
        return "--:--"
      }
      let remainingMinutes = Math.floor(remainingTime / 60)
      let remainingSeconds = Math.floor(remainingTime % 60)
      function zerofill(number, width) {
        let str = number.toString();
        while (str.length < width) {
          str = "0" + str;
        }
        return str;
      }
      return zerofill(remainingMinutes, 2) + ":" + zerofill(remainingSeconds, 2)
    }

    return <div className="test-time-remained">
      <div className="test-time-remained-title">Time</div>
      <div className="test-time-remained-time">{presentRemainTime(remainTime)}</div>
  </div>
}

export default TestTimeRemained;