import './TestItem.css';
import { Skeleton } from '@mui/material';
import React from 'react';

function TestItemSkeleton() {
    return <div className="test-card">
    <div className="test-card-top">
      <div className="test-card-top-left">
        <div className="test-card-title">
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '17px', width: (84 - Math.floor(Math.random() * 36)) + '%' }} />
        </div>
        <div className="test-card-date">
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '15px', width: '34%' }} />
        </div>
      </div>
      <div className="test-card-top-right">
      </div>
    </div>
    <div className="test-card-divider"></div>
    <div className="test-card-bottom">
    <div className="test-card-bottom-left">
        <div className="test-card-class">
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 84, height: '100%' }} />
        </div>
        <div className="test-card-description">
          <div className="test-card-numbers-of-question">
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 120, height: '100%' }} />
          </div>
          <div className="test-card-time-limits">
          </div>
        </div>
      </div>
      <div className="test-card-bottom-right">
            <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '7px', width:82, height:30 }} />
      </div>
    </div>
  </div>
}

export default TestItemSkeleton;