import './Recorder.css';
import React, { useRef, useState, useEffect } from 'react';
import { Slider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayIcon from '@mui/icons-material/PlayArrow';
import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import AlertDialog, { AlertActionButton } from '../Alert/Alert';
import toast from 'react-hot-toast';

const State = {
  READY : "ready" ,
  READY_TO_RECORDING : "ready_to_recording",
  RECORDING : "recording",
  UPLOADING : "uploading",
  COMPLETE : "complete"
  };
Object.freeze(State);

function Recorder({ src, type, onRecordEnded }) {
    const [sourceSrc, setSourceSrc] = useState(src)
    const [state, setState] = useState(src == null ? State.READY : State.COMPLETE)
    const [audioProgress, setAudioProgress] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showsAlertDialog, setShowsAlertDialog] = useState(false)
    const audioRef = useRef(null)
    const [mediaRecorder, setMediaRecorder] = useState(null)

    async function startRecording() {
      let localStream = null;
      const constraints = { audio: true };
      if (!navigator.mediaDevices.getUserMedia){
        toast.error("해당 환경은 녹음을 지원하지 않습니다.\n[Detail] !navigator.mediaDevices.getUserMedia")
      }else{
        if (MediaRecorder == undefined) {
            toast.error("해당 환경은 녹음을 지원하지 않습니다.\n[Detail] MediaRecorder == undefined")
        } else {
          localStream = await navigator.mediaDevices.getUserMedia(constraints)
        }
      }
      if (localStream == null) {
        toast.error("마이크가 존재하지 않는 기기입니다.")
      } else {
        var chunks = [];
        var recorder = null;
        if (typeof MediaRecorder.isTypeSupported == 'function') {
          var options = { audioBitsPerSecond: 8000 };
          if (MediaRecorder.isTypeSupported("audio/webm")) {
            options['mimeType'] = 'audio/webm'
          } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
            options['mimeType'] = 'audio/mp4'
          }
          recorder = new MediaRecorder(localStream, options);
        }else{
          recorder = new MediaRecorder(localStream);
        }
        recorder = new MediaRecorder(localStream, options);
        setMediaRecorder(recorder)
        
        recorder.ondataavailable = function(e) {
          if (e.data && e.data.size > 0) {
            chunks.push(e.data);
          }
        };
    
        recorder.onstop = async () => {
          if (chunks.length == 0) {
            setSourceSrc(null)
            onRecordReset()
            return
          } 

          var blob = new Blob(chunks, { type: recorder.mimeType });
          let audioURL = URL.createObjectURL(blob);
          setSourceSrc(audioURL)
          
          if (MediaRecorder.isTypeSupported("audio/webm")) {
            fetch(audioURL).then(r => r.blob()).then(blobFile => new File([blobFile], "answer.webm", { type: "audio/webm" }))
            .then((file) => {
              onRecordEnded(file, (response) => {
                  onUploadCompleted(response)
              })
            })
          } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
            fetch(audioURL).then(r => r.blob()).then(blobFile => new File([blobFile], "answer.mp4", { type: "audio/mp4" }))
            .then((file) => {
              onRecordEnded(file, (response) => {
                  onUploadCompleted(response)
              })
            })
          }

          localStream.getTracks().forEach((track) => {
            track.stop()
          })
        };

        recorder.onstart = async () => {
          onRecordingStart()
        }
    
        recorder.start();
        return true
      }
      return false
      
    }

    function stopRecording() {
      mediaRecorder.stop();
      setMediaRecorder(null)
      return true
    }
      
      function formatTime(remainingTime) {
        if (remainingTime === null) {
          return "--:--"
        }
        let remainingMinutes = Math.floor(remainingTime / 60)
        let remainingSeconds = Math.floor(remainingTime % 60)
        function zerofill(number, width) {
          let str = number.toString();
          while (str.length < width) {
            str = "0" + str;
          }
          return str;
        }
        return remainingMinutes > 0 ? (zerofill(remainingMinutes, 2) + ":" + zerofill(remainingSeconds, 2)) : remainingSeconds
      }

    function onAudioTimeUpdate() {
        var audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        var current = audio.currentTime;
        var percent = (current / audio.duration) * 100
        if (isNaN(percent)) {
          percent = 0
        }
        setAudioProgress(percent)
    }

    function onAudioDurationChange() {
        var audio = audioRef.current
        if (isNaN(audio.duration) || audio.duration == Infinity) {
          audio.currentTime = 1e101
          return
        }
        setDuration(formatTime(audio.duration))
    }

    useEffect(() => {
      if (audioRef == null) {
          return
      }
      setTimeout(() => {
        audioRef.current.load()
      }, 1)
    }, [sourceSrc]);

    function onAudioEnded() {
        setIsPlaying(false)
    }

    async function onAudioPausePlay() {
        var audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        if (audio.currentTime === audio.duration) {
            audio.currentTime = 0
        }
        if (isPlaying) {
          await audio.pause()
        } else {
          await audio.play()
        }
        setIsPlaying(!isPlaying)
    }

    function onSliderClick(event) {
        let audio = audioRef.current
        if (isNaN(audio.duration)) {
            return
        }
        audio.currentTime = audio.duration * event.target.value * 0.01
    }

    async function onRecordReset() {
        let audio = audioRef.current
        if (isNaN(audio.duration) === false) {
          audio.currentTime = 0
          await audio.pause()
        }
        setIsPlaying(false)
        setState(State.READY)
    }
    
    async function onRecordingClick() {
      setState(State.READY_TO_RECORDING)
      if(await startRecording()) {
        setDuration(formatTime(null))
      }
    }

    async function onRecordingStart() {
      setState(State.RECORDING)
    }

    function onStopClick() {
      if(stopRecording()) {
        if (state == State.RECORDING) {
          setState(State.UPLOADING)
        } else {
          setState(State.READY)
        }
      }
    }

    function onUploadCompleted(response) {
        if (response != null && response.resources.length > 0 && response.resources[0].url) {
          setSourceSrc(response.resources[0].url)
          setState(State.COMPLETE) 
        } else {
          toast.error("녹음 업로드에 실패하였습니다.")
          setState(State.READY)
        }
    }

    function onRetryClick() {
        setShowsAlertDialog(true)
    }

    const recorderItem = (state) => {
        switch (state) {
            case State.READY:
                return <div className="test-answer-recorder">
                    <div className="test-answer-recorder-description">
                        준비 후 녹음버튼을 눌러주세요.
                    </div>
                    <div className="test-answer-recorder-button scale-button ripple-key-color" onClick={async () => { await onRecordingClick() }}>
                        <MicIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#ffffff'}} />
                    </div>
                </div>
            case State.READY_TO_RECORDING:
                  return <div className="test-answer-recorder">
                      <div className="test-answer-recorder-description">
                        녹음 준비중...
                      </div>
                      <div className="test-answer-recorder-button test-answer-recorder-loading-button">
                       <div className="test-answer-recorder-loading-spinner"></div>
                      </div>
                  </div>
            case State.RECORDING:
                return <div className="test-answer-recorder recording-active">
                    <div className="test-answer-recorder-description">
                      녹음중...
                    </div>
                    <div className="test-answer-recorder-button scale-button ripple-key-color" onClick={onStopClick}>
                        <StopIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#ffffff'}} />
                    </div>
                </div>
            case State.UPLOADING:
                return <div className="test-answer-recorder">
                        <div className="test-answer-recorder-timeline">
                        <div className="test-answer-recorder-timeline-top">
                            <div className="test-answer-recorder-timeline-description">
                                <div className="test-answer-recorder-seconds">
                                    <AccessTimeIcon sx={{fontSize: '14px', lineHeight: '17px', marginRight: '3px'}} />
                                    {duration}s
                                </div>
                                <div className="test-answer-recorder-completed-description">
                                    업로드중...
                                </div>
                            </div>
                        </div>
                        <div className="test-answer-recorder-timeline-bottom">
                            <div className="test-answer-recorder-timeline-controls">
                            <Slider size="small" value={audioProgress} sx={{ padding:'0 0 0 0 !important', height: '4px', '& .MuiSlider-thumb': { color: "#760023" }, '& .MuiSlider-track': { color: "#760023" }, '& .MuiSlider-rail': { color: "#EDEDED" }, '& .MuiSlider-active': { color: "#760023" }}} />
                            </div>
                        </div>
                    </div>
                    <div className="test-answer-recorder-button test-answer-recorder-loading-button">
                       <div className="test-answer-recorder-loading-spinner"></div>
                    </div>
                    </div>
            case State.COMPLETE:
                return <div className="test-answer-recorder">
                    <div className="test-answer-recorder-timeline">
                        <div className="test-answer-recorder-timeline-top">
                            <div className="test-answer-recorder-timeline-description">
                                <div className="test-answer-recorder-seconds">
                                    <AccessTimeIcon sx={{fontSize: '14px', lineHeight: '17px', marginRight: '3px'}} />
                                    {duration}s
                                </div>
                                <div className="test-answer-recorder-completed-description">
                                    녹음완료!
                                    <div className="test-answer-recorder-completed-retry-button button-pointer" onClick={onRetryClick}>Retry</div>
                                </div>
                            </div>
                        </div>
                        <div className="test-answer-recorder-timeline-bottom">
                            <div className="test-answer-recorder-timeline-controls">
                            <Slider size="small" value={audioProgress} onChange={onSliderClick} sx={{ padding:'0 0 0 0 !important', height: '4px', '& .MuiSlider-thumb': { color: "#760023" }, '& .MuiSlider-track': { color: "#760023" }, '& .MuiSlider-rail': { color: "#EDEDED" }, '& .MuiSlider-active': { color: "#760023" }}} />
                            </div>
                        </div>
                    </div>
                    <div className="test-answer-recorder-button scale-button ripple-key-color" onClick={onAudioPausePlay}>
                        {
                            isPlaying ? <PauseIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#ffffff'}} /> :
                                <PlayIcon sx={{display: 'flex', alignSelf: 'center', width: '42px', fontSize: '24px', color: '#ffffff'}} />
                        }
                    </div>
                </div>
                default:
                  break
        }
    }
    return <div>
        {recorderItem(state)}
        <audio ref={audioRef} onTimeUpdate={onAudioTimeUpdate} onDurationChange={onAudioDurationChange} onEnded={onAudioEnded}>
            <source src={sourceSrc} type={type} />
        </audio>
        <AlertDialog open={showsAlertDialog} 
            title="녹음을 다시 하시겠습니까?" 
            description="기존 녹음된 파일은 삭제됩니다." 
            buttons={[
            { "title": "취소", "action": () => {
                setShowsAlertDialog(false)
            }},
            { "title": "확인", "type": AlertActionButton.KEYCOLOR, "action": async () => {
              setShowsAlertDialog(false)
              onRecordReset()
          }} ]} />
    </div>
}

export default Recorder;