import './Alert.css';
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertActionButton = {
  DEFAULT : "default" ,
  KEYCOLOR : "keycolor",
};
Object.freeze(AlertActionButton);

export default function AlertDialog({ imageUrl, title, description, open, buttons }) {

  const buttonClassName = (type) => {
    if (type == null) {
      return "alert-dialog-button alert-dialog-button-default button-pointer"
    }
    switch (type) {
      case AlertActionButton.DEFAULT:
        return "alert-dialog-button alert-dialog-button-default button-pointer"
      case AlertActionButton.KEYCOLOR:
        return "alert-dialog-button alert-dialog-button-keycolor ripple-key-color"
      default:
        return "alert-dialog-button alert-dialog-button-default button-pointer"
    }

  }
  return (
    <div>
      <Dialog
        className="alert-dialog"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="alert-dialog-container">
        <div className="alert-dialog-content">
        {imageUrl == null ? <div></div> : <img className="alert-dialog-image" src={imageUrl} alt='' />}
        <div className="alert-dialog-title">{title}</div>
        {description == null ? <div></div> : <div className="alert-dialog-description">{description}</div>}
        </div>
          
          <div className="alert-dialog-action">
          {buttons.map((value, index) => {
              return <div className={buttonClassName(value["type"])} key={index} onClick={value["action"]}>{value["title"]}</div>
          })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}