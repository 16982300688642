import toast, { Toaster } from 'react-hot-toast';

function CustomToaster() {
    return <Toaster
      position='top-center'
      containerStyle={{
        top: 56,
      }} 
      toastOptions={{
        className: 'window-toast',
        style: {
          padding: '8px',
          color: '#760023',
        },
        iconTheme: {
          primary: '#760023',
          secondary: '#FFFAEE',
        },
      }}/>
  }
  
  export default CustomToaster;