import { atom, selector } from 'recoil';
import axios from 'axios';

export const accessTokenState = atom({
  key: 'AccessTokenState',
  default: selector({
    key: 'AccessTokenState/Silent-Refresh',
    get: async () => {
      try {
        const response = await axios.post('/users/token/silent-refresh/');
        return response.data;
      } catch (error) {
        return null;
      }
    },
    }),
});
