
import './TestQuestion.css';
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Recorder from '../../../Common/Recorder/Recorder'
import AudioPlayer from '../../../Common/AudioPlayer/AudioPlayer';
import toast from 'react-hot-toast';
import { Stack } from '@mui/material';

const State = {
  READY : "ready" ,
  RECORDING : "recording",
  UPLOADING : "uploading",
  COMPLETE : "complete"
};
Object.freeze(State);

function TestQuestion({ testId, index, score, question, answer, onSubmitAnswer }) {    
    const [isSubmited, setIsSubmited]  = useState(false)
    const [contentValue, setContentValue] = useState("");
    const [currentAnswer, setCurrentAnswer] = useState(answer);
    const [fullScreenImageUrl, setFullScreenImageUrl] = useState(null);
    const textareaRef = useRef(null);
    const onAnswer = (data, callback) => {
      toast.loading("제출중..", { id: "answer_" + question.id })
      axios.post("/tests/" + testId + "/answers?question_id=" + question.id, data).then(response => {  
        toast.success("제출완료!", { id: "answer_" + question.id })
        onSubmitAnswer(question, response.data)
        setIsSubmited(true)
        callback(response.data)
      }).catch(error => {
        toast.error("제출에 실패하였습니다.", { id: "answer_" + question.id })
        callback(null)
      });
    }

    useEffect(() => {
      if (currentAnswer == null) {
          return
      }
      setIsSubmited(true)
      setupTextArea()
    }, [currentAnswer]);

    const setupTextArea = () => {
      if (currentAnswer == null) {
          return
      }
      if (currentAnswer.content == null) {
          return
      }
      setContentValue(currentAnswer.content);
      setTimeout(() => {
          const textarea = textareaRef.current;
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight + 3}px`;
      }, 1)
    }
    const handleTextAreaInputChange = (event) => {
      setContentValue(event.target.value)
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight + 3}px`;
    };

    const handleTextAreaEndEdit = (event) => {
      const textareaValue = event.target.value;
      if (currentAnswer != null) {
        if (currentAnswer.content != null && textareaValue == currentAnswer.content) {
          return
        }
      }
      const data = new FormData();
      data.append("data", JSON.stringify({
          "content": textareaValue
      }))
      onAnswer(data, (response) => {
        if (response == null) {
          return
        }
        setCurrentAnswer(response)
      })
    }

    const handleChoiceTouchEnded = (event, value) => {
      const data = new FormData();
      data.append("data", JSON.stringify({
          "content": value.choice.id
      }))
      onAnswer(data, (response) => {
        if (response == null) {
          return
        }
        setCurrentAnswer(response)
      })
    }

    function onRecordEnded(file, callback) {
      const data = new FormData();
      data.append("file", file)
      onAnswer(data, (response) => {
        if (response == null) {
          callback(null)
          return
        }
        setCurrentAnswer(response)
        callback(response)
      })
    }

    return <div className={ isSubmited ? "test-question-card solved" : "test-question-card" }>
        <div className="test-question-area">
            <div className="test-question-title-area">
                <div className="test-question-number">{ index.toString().padStart(2, '0') }</div>
                <div className="test-question-title-label">{question.title}</div>
            </div>
            <div className="test-question-content-area">
                <div className="test-question-content-label">{question.content}</div>
            </div>
            <Stack spacing={2} className="test-question-resources">
            {
                  question.resources == null ? <div></div> :
                  question.resources.map((value, index) => {
                    if (value.content_type.indexOf("audio/") === 0) {
                        return (
                            <AudioPlayer key={index} src={value.url} type={value.content_type} />
                        )
                      } else if (value.content_type.indexOf("video/") === 0) {
                        return (
                                <video playsInline preload='auto' className='test-question-video' key={index} controls>
                                    <source src={value.url} type={value.content_type} />
                                    브라우저가 비디오를 지원하지 않습니다.
                                </video>
                          )
                      } else if (value.content_type.indexOf("image/") === 0) {
                        return (
                            <div className='test-question-image' key={index}>
                              <img src={value.url}></img>
                              {/* <TestQuestionImage src={value.url} /> */}
                              {/* <div className='test-question-image-full-screen button-pointer' onClick={() => {onFullScreenClick(value.url)}}>
                                <FullscreenIcon sx={{display: 'flex', alignSelf: 'center', width: '24px', fontSize: '24px', color: 'white'}} />
                              </div> */}
                            </div>
                        )
                      } else {
                        return "지원되지 않는 콘텐츠 유형입니다."
                      }
                  })
            }
            </Stack>
            <div className="test-question-choices">
            {
              question.choices == null ? <div></div> :
                question.choices.map((value, index) => {
                  return <div className="test-question-choices-choice" key={index}>
                      <div className="test-question-choices-choice-index">{index + 1}.</div>
                      <div className="test-question-choices-choice-content">{value.choice.content}</div>
                    </div>
                })
            }
            </div>
            <div className="test-question-mark">
                { score } points
            </div>
        </div>
        <div className="test-question-divider" />
        <div className="test-answer-area">
            {question.type == null ? (
                <div></div>
            ) : (
                <div>
                {question.type === "T" ? (
                    <textarea 
                        ref={textareaRef}
                        className="test-answer-text" 
                        wrap="soft" 
                        onChange={handleTextAreaInputChange}
                        onBlur={handleTextAreaEndEdit}
                        value={ contentValue } />
                ) : question.type === "R" ? (
                  (currentAnswer == null || currentAnswer.resources.length == 0) ? <Recorder onRecordEnded={onRecordEnded} /> : 
                    <Recorder src={currentAnswer.resources[0].url} type={currentAnswer.resources[0].content_type} onRecordEnded={onRecordEnded} />
                ) : question.type === "M" ? (
                    <div className="test-answer-choices">
                    {
                      question.choices == null ? <div></div> :
                        question.choices.map((value, index) => {
                          var isSelected = false
                          if (currentAnswer != null && currentAnswer.choices.length > 0 && currentAnswer.choices[0].choice.id == value.choice.id) {
                            isSelected = true
                          }
                          return <div className="test-answer-choices-choice" key={index}>
                              <div className={ isSelected ? "test-answer-choices-choice-index solved" : "test-answer-choices-choice-index" } onClick={(event) => { handleChoiceTouchEnded(event, value) }}>{index + 1}</div>
                            </div>
                        })
                    }
                    </div>
                ) : (
                    <div>지원되지 않는 문제 유형입니다.</div>
                )}
                </div>
            )}
        </div>
        {
          fullScreenImageUrl == null ? <div></div> :
          <div className='test-question-full-screen-image'>
            
          </div>
        }
    </div>
}

export default TestQuestion;