import './LoginForm.css';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { navigationBarState } from '../../../states/NavigationBar';
import { accessTokenState } from '../../../states/Token'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import QrCodeIcon from '@mui/icons-material/QrCode';

function LoginForm() {
    const navigate = useNavigate();
    const setAccessToken = useSetRecoilState(accessTokenState);
    const [id, setID] = useState("")
    const [password, setPassword] = useState("")
    const passwordInputRef = useRef(null)

    const setNavigationBarState = useSetRecoilState(navigationBarState);
    
    useEffect(() => {
        setNavigationBarState(null);
    }, []);

    function login(id, password) {
        if (id == "") {
            toast.error("ID를 입력하세요.", { id: 'login' })
            return
        } else if (password == "") {
            toast.error("Password를 입력하세요.", { id: 'login' })
            return
        }
        toast.loading("로그인중...", { id: 'login' })
        const data = new FormData();
        data.append('username', id);
        data.append('password', password);
        axios.post('/users/login/', data).then(response => {
            // @@ 심사대응
            // let user = response.data.user
            // if (user.type == "S" && window.navigator.userAgent.indexOf("kutest_App") == -1) {
            //     axios.post('/users/logout/', null).then(response => {
            //         toast.error("응시자 계정은 '통번역자격시험' 모바일 앱을 통해\n로그인이 가능합니다.", { id: 'login' })
            //     }).catch(error => {
            //         toast.error("응시자 계정은 '통번역자격시험' 모바일 앱을 통해\n로그인이 가능합니다.", { id: 'login' })
            //     });
            // } else {
                let user = response.data.user
                toast.success(user.nickname + "님 환영합니다.", { id: 'login' })
                setAccessToken(response.data)
            // }
        }).catch(error => {
            if (error.response.status == 401) {
                toast.error(error.response.data.detail, { id: 'login' })
                setPassword("")
                passwordInputRef.current.focus()
            } else {
                toast.error("일시적인 오류로 로그인할 수 없습니다.", { id: 'login' })
            }
        });
    }

    const onLogin = () => {
        login(id, password)
    }

    function onChangeID({target: {value}}) {
        setID(value)
    }

    function onKeyDownID(event) {
        if (event.key == 'Enter') {
            passwordInputRef.current.focus()
        }
    }

    function onChangePassword({target: {value}}) {
        setPassword(value)
    }

    function onKeyDownPassword(event) {
        if (event.key == 'Enter') {
            onLogin()
        }
    }

    function onPrivatePolicyClick() {
        navigate("/privacy-policy")
    }

    function onQRLoginClick() {
        window.qrLoggedIn = function(id, password) {
            login(id, password)
        }
        if (window.KUTAppInterface) {
            window.KUTAppInterface.qrLogin();
        } else if (window.webkit && window.webkit.messageHandlers.KUTAppInterface) {
            window.webkit.messageHandlers.KUTAppInterface.postMessage("qrLogin");
        }
    }

    return <div>
        <div className="loggedout-container">
            <div className="loggedout-information">
                <img className="loggedout-logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt='' />
                <div className="loggedout-title">통번역 자격시험</div>
                <div className="loggedout-description">Interpretation & Translation Evaluation Test</div>
            </div>
            <div className="login-form">
                <div className="input-field">
                    <div className="input-field-label">ID</div>
                    <input className="input-field-value" type="text" value={id} onKeyDown={onKeyDownID} onChange={onChangeID} />
                </div>
                <div className="input-field">
                    <div className="input-field-label">Password</div>
                    <input ref={passwordInputRef} className="input-field-value" type="password" value={password} onKeyDown={onKeyDownPassword} onChange={onChangePassword} />
                </div>
                <button className="login-form-button ripple-key-color" onClick={onLogin}>Login</button>
                {
                    window.navigator.userAgent.indexOf("kutest_App") == -1 ? <div></div> : 
                    <button className="qr-login-form-button ripple-key-color-outlined" onClick={onQRLoginClick}><QrCodeIcon /><div className="qr-login-text">QR Login</div></button>
                }
                <div className="loggedout-bottom">
                    <div className='loggedout-private-info-policy' onClick={onPrivatePolicyClick}>개인정보처리방침</div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginForm;