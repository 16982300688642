import './LoggedIn.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TestList from '../Test/List/TestList'
import TestRoot from '../Test/Root/TestRoot'
import NavBar from '../Base/NavBar'

function LoggedIn() {
    return <div>
        <BrowserRouter>
            <NavBar></NavBar>
            <div className="safe-area">
                <Routes>
                    <Route path="/" element={<TestList />} />
                    <Route path="/tests" element={<TestList />} />
                    <Route path="/tests/:testId" element={<TestRoot />} />
                </Routes>
            </div>
        </BrowserRouter>
    </div>
}

export default LoggedIn;