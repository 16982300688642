import './NavBar.css';
import React, { useState } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { accessTokenState } from '../../states/Token'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationBarState } from '../../states/NavigationBar'
import { useNavigate } from 'react-router-dom';
import AlertDialog, { AlertActionButton } from '../Common/Alert/Alert';
import toast from 'react-hot-toast';

function NavBar() {
    const navigate = useNavigate();
    const setAccessToken = useSetRecoilState(accessTokenState);
    const navigationBar = useRecoilValue(navigationBarState);
    const [showsLogoutAlertDialog, setShowsLogoutAlertDialog] = useState(false);
    const [showsBackAlertDialog, setShowsBackAlertDialog] = useState(false);

    const onLogout = () => {
        axios.post('/users/logout/', null).then(response => {
            toast.success("정상적으로 로그아웃 되었습니다")
            setAccessToken(null)
        }).catch(error => {
            setAccessToken(null)
        });
    }

    const onBack = () => {
        navigate(-1)
    }

    const navigationItem = (item) => {
        if (item == null) {
            return <div></div>
        }
        switch (item["type"]) {
            case "logout":
                return <div className="navigation-item">
                    <CloseIcon onClick={() => { setShowsLogoutAlertDialog(true) }} sx={{fontSize: '14px', color: "#760023"}} />
                    <AlertDialog open={showsLogoutAlertDialog} 
                        title="로그아웃 하시겠습니까?"
                        description="확인을 누르시면 안전하게 로그아웃 됩니다."
                        buttons={[
                        { "title": "취소", "action": () => {
                            setShowsLogoutAlertDialog(false)
                        }},
                        { "title": "확인", "type": AlertActionButton.KEYCOLOR, "action": async () => {
                            setShowsLogoutAlertDialog(false)
                            onLogout()
                    }} ]} />
                </div>
            case "back":
                return <div className="navigation-item">
                    <ArrowBackIosNewIcon onClick={() => { 
                        if(item["dialog"] == null) {
                            onBack()
                        } else {
                            setShowsBackAlertDialog(true)
                        }
                    }} sx={{fontSize: '14px', color: "#760023"}} />
                    { item["dialog"] != null ? <AlertDialog open={showsBackAlertDialog}
                        title={item["dialog"]["title"]} 
                        description={item["dialog"]["description"]} 
                        buttons={[
                    { 
                        "title": item["dialog"]["button-title-cancel"] == null ? "취소" : item["dialog"]["button-title-cancel"],
                        "action": () => {
                            setShowsBackAlertDialog(false)
                        }},
                    { 
                        "title": item["dialog"]["button-title-back"] == null ? "뒤로" : item["dialog"]["button-title-back"],
                        "type": AlertActionButton.KEYCOLOR, 
                        "action": async () => {
                            setShowsBackAlertDialog(false)
                            navigate(-1)
                        }
                    } ]} /> : <div></div> }
                </div>
            case "reload":
                return <div className="navigation-item-none">
                    <RefreshIcon onClick={() => { 
                        if(item["onreload"] != null) {
                            item["onreload"]()
                        }
                    }} sx={{fontSize: '24px', color: "#760023"}} />
                </div>
            case "text":
                return <div className="navigation-item-text">
                    { item["value"] }
                </div>
            default:
                return <div></div>
        }
    }

    return (
        navigationBar == null ? <div></div> : 
        <div className="navigation-bar">
            <div className='navigation-bar-items'>
                <div className="navigation-left-item">{navigationItem(navigationBar['left-item'])}</div>
                <div className="navigation-title">{navigationBar.title}</div>
                <div className="navigation-right-item">{navigationItem(navigationBar['right-item'])}</div>
            </div>
        </div>
    )
}

export default NavBar;