import './TestRoot.css'
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TestResourceProvider from './ResourceProvider/TestResourceProvider';
import TestMain from './Main/TestMain';
import TestComplete from '../Complete/TestComplete'
import toast from 'react-hot-toast';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const TestRootState = {
    LOADING : "LOADING",
    TESTING : "TESTING",
    TEST_SUBMITTED : "EXAM_SUBMITTED",
    TEST_ENDED : "EXAM_ENDED",
};

function TestRoot() {
    const navigate = useNavigate();
    const { testId } = useParams();
    const [testData, setTestData] = useState(null)
    const [testState, setTestState] = useState(TestRootState.LOADING)

    function fetchTestInfo() {
        axios.get('/tests/' + testId).then(response => {
            setTestData(response.data)
            setTestState(TestRootState.TESTING)
        }).catch(error => {
            if (error.response.status === 404) {
                toast.error("잘못된 경로입니다.")
            } else if (error.response.status === 401) {
                toast.error("접근 권한이 없습니다.")
            } else if (error.response.status === 400) {
                toast.error("시험 정보를 받아오지 못했습니다.\n관리자에게 문의해주세요.")
            }
            navigate('/')
        });
    }
    
    function onTestEnded() {
        setTestState(TestRootState.TEST_ENDED)
    }

    function onTestSubmit() {
        setTestState(TestRootState.TEST_SUBMITTED)
    }

    useEffect(() => {
        fetchTestInfo()
      }, [])

    const makeDom = () => {
        switch (testState) {
            case TestRootState.LOADING:
                return <div></div>
            case TestRootState.TESTING:
                if (testData == null) {
                    toast.error("시험 정보를 받아오지 못했습니다.\n관리자에게 문의해주세요.")
                    navigate('/')
                    return <div></div>
                }
                const current_time = new Date(testData.current_time);
                const end_time = new Date(testData.test.end_time);
                if (end_time < current_time) {
                    setTestState(TestRootState.TEST_ENDED)
                    return <div></div>
                }
                var resources = [];
                if (testData.answer_sheet != null) {
                    testData.answer_sheet.answers.forEach(value => {
                        if (value.resources != null) {
                            resources = resources.concat(value.resources)
                        }
                    })
                }
                if (testData.question_sheet != null) {
                    testData.question_sheet.questions.forEach(value => {
                        if (value.question.resources != null) {
                            resources = resources.concat(value.question.resources)
                        }
                    })
                }
                return <TestResourceProvider resources={resources} fallback={<div>Resource Loading...</div>}>
                        <React.Suspense fallback={<div>Image Loading...</div>}>
                            <TestMain testId={testId} testData={testData} onTestEnded={onTestEnded} onTestSubmit={onTestSubmit} />
                        </React.Suspense>
                    </TestResourceProvider>
            case TestRootState.TEST_ENDED:
                return <CSSTransition classNames="right" timeout={300}>
                    <TestComplete title="시험종료" description="시험시간이 종료되었습니다. 입력하신 답안은 정상적으로 제출되었습니다." onConfirmButtonClick={() => { navigate('/') }} />
                </CSSTransition>
            case TestRootState.TEST_SUBMITTED:
                return <CSSTransition classNames="right" timeout={300}>
                    <TestComplete title="제출완료" description="시험이 정상적으로 제출되었습니다." onConfirmButtonClick={() => { navigate(-1) }} />
                </CSSTransition>
        }
    }
    return <TransitionGroup className="test-root-trainsition-wrapper">
        { makeDom() }
    </TransitionGroup>
}

export default TestRoot;