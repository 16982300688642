import './TestItem.css';
import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

function TestItem(props) {
    const { test, onStart, onContinue } = props;
    
    function formatDateString(inputDateString) {
      const date = new Date(inputDateString);
      const year = String(date.getFullYear()).slice(-2); // 년도의 마지막 2자리
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      // 오전(AM) 또는 오후(PM) 결정
      const amOrPm = hours < 12 ? '오전' : '오후';
      
      // 시간을 12시간 형식으로 변환
      const formattedHours = (hours % 12 || 12).toString();
      
      return `${year}/${month}/${day} ${amOrPm} ${formattedHours}:${minutes}`;
    }

    const makeActionButton = (status) => {
      switch (status) {
        case "WAITING":
          return <div className="test-card-icon">
            <LockIcon sx={{display: 'flex', alignSelf: 'center', width: '24px', fontSize: '24px', color: '#760023'}} />
          </div>
        case "EXPIRED":
          return <div>  </div>
        case "READY":
          return <div className="test-card-action scale-button ripple-key-color" onClick={onStart}>Start</div>
        case "TESTING":
          return <div className="test-card-action scale-button ripple-key-color" onClick={onContinue}>Continue</div>
        case "EDITING":
          return <div className="test-card-action scale-button ripple-key-color" onClick={onContinue}>Edit</div>
        case "COMPLETED":
          return <div className="test-card-icon">
            <TaskAltIcon sx={{display: 'flex', alignSelf: 'center', width: '24px', fontSize: '24px', color: '#760023'}} />
          </div>
        default:
          return <div></div>
      }
    }
    const makeTestCardClass = (status) => {
      switch (status) {
        case "TESTING":
          return "test-card ready"
        case "READY":
          return "test-card ready"
        default:
          return "test-card"
      }
    }
    const makeTestCardTopClass = (status) => {
      switch (status) {
        case "COMPLETED":
          return "test-card-top completed"
        default:
          return "test-card-top"
      }
    }
    const makeTestCardBottomClass = (status) => {
      switch (status) {
        case "COMPLETED":
          return "test-card-bottom completed"
        default:
          return "test-card-bottom"
      }
    }
    const makeTestCardDate = (test) => {
      switch (test.status) {
        case "WAITING":
          return formatDateString(test.start_time) + "~"
        default:
          return test.end_time == null ? "기한없음" : "~" + formatDateString(test.end_time)
      }
    }

    return <div className={makeTestCardClass(test.status)}>
    <div className={makeTestCardTopClass(test.status)}>
      <div className="test-card-top-left">
        <div className="test-card-title">{test.title}</div>
        <div className="test-card-date">{makeTestCardDate(test)}</div>
      </div>
      <div className="test-card-top-right">
        <div className="test-card-mark">{test.question_sheet.total_score}<br />points</div>
      </div>
    </div>
    <div className="test-card-divider"></div>
    <div className={makeTestCardBottomClass(test.status)}>
    <div className="test-card-bottom-left">
        <div className="test-card-class">{test.academy_class_name}</div>
        <div className="test-card-description">
          <div className="test-card-numbers-of-question">
            {test.question_sheet.number_of_questions} questions
          </div>
          <div className="test-card-time-limits">
            <i></i>
            <div>{Math.round(test.question_sheet.time_limit * 100 / 60) / 100}m</div>
          </div>
        </div>
      </div>
      <div className="test-card-bottom-right">
        {makeActionButton(test.status)}
      </div>
    </div>
  </div>
}

export default TestItem;