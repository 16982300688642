import './LoggedOut.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from '../Base/NavBar'
import LoginForm from './LoginForm/LoginForm';
import PrivatePolicy from './PrivatePolicy/PrivatePolicy';

function LoggedOut() {
    return <div>
        <BrowserRouter>
            <NavBar></NavBar>
            <div className="safe-area">
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    <Route path="/privacy-policy" element={<PrivatePolicy />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
        </BrowserRouter>
    </div>
}
export default LoggedOut;