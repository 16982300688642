import { useState, useEffect } from 'react';

function TestResourceProvider({ children, resources, fallback }) {
    const [loadedAllResources, setLoadedAllResources] = useState({});
    const resourceUrls = resources.map((value) => value.url).filter((value) => value != null)
    useEffect(() => {
        resources.forEach((resource) => {
            if (resource.content_type.startsWith("image")) {
                const imgElement = new Image();
                imgElement.src = resource.url;
                imgElement.onload = () => {
                    setLoadedAllResources(prevState => ({
                        ...prevState,
                        [resource.url]: true
                    }));
                };
                imgElement.onerror = () => {
                    setLoadedAllResources(prevState => ({
                        ...prevState,
                        [resource.url]: true
                    }));
                };
            } else if (resource.content_type.startsWith("audio")) {
                const audioElement = new Audio(resource.url);
                audioElement.onloadeddata = () => {
                    setLoadedAllResources(prevState => ({
                        ...prevState,
                        [resource.url]: true
                    }));
                };
                audioElement.onerror = () => {
                    setLoadedAllResources(prevState => ({
                        ...prevState,
                        [resource.url]: true
                    }));
                };
            } else {
                setLoadedAllResources(prevState => ({
                    ...prevState,
                    [resource.url]: true
                }));
            }
        });
    }, []);
    const allResourcesLoaded = resourceUrls.every(url => loadedAllResources[url]);
    // return allResourcesLoaded ? children : fallback
    return children
}

export default TestResourceProvider;