import './TestList.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import TestItem from './Item/TestItem';
import TestItemSkeleton from './Item/TestItemSkeleton';
import { navigationBarState } from '../../../states/NavigationBar';
import { LoggedInState } from '../../../states/LoggedIn';
import Lottie from "lottie-react";
import confirmLottieData from './confirm.json'
import emptyLottieData from './empty.json'

function TestList() {
  const navigate = useNavigate();
  const setNavigationBarState = useSetRecoilState(navigationBarState);
  const loggedIn = useRecoilValue(LoggedInState)
  const [fetchError, setFetchError] = useState(null);
  const [testList, setTestList] = useState(null);
  const [currentTest, setCurrentTest] = useState(null);
  const testOrder = (test) => {
    switch (test.status) {
      case "READY":
        return 0;
      case "TESTING":
        return 0;
      case "WAITING":
        return 1;
      case "EXPIRED":
        return 3;
      default:
        return 2;
    }
  }
  
  function fetchTestList() {
    setFetchError(null)
    setTestList(null)
    
    axios.get('/tests/').then((response) => {
      let testList = response.data
      testList.sort((left, right) => {
        let leftOrder = testOrder(left)
        let rightOrder = testOrder(right)
        if (leftOrder == rightOrder) {
          return left.end_time - right.end_time
        }
        return leftOrder - rightOrder
      });
      setTestList(testList);
    }).catch((error) => {
      setFetchError(error);
    });
  }
  // const startExamAlertDescription = (test) => {
  //   if (test == null) {
  //     return
  //   } 
  //   const questionSheet = test.question_sheet
  //   const numberfQuestions = questionSheet.number_of_questions
  //   const timeLimit = (test.question_sheet.time_limit / 60)
  //   return "해당 시험은 총 " + numberfQuestions + "문제로 이루어져있으며\n제한시간은 " + timeLimit / 60 + "분입니다."
  // }

  useEffect(() => {
    setNavigationBarState({
      title: 'Exams',
      'left-item': { type: 'logout' },
      'right-item': { type: 'reload', onreload: fetchTestList },
    });
    fetchTestList();
  }, []);

  let doms;
  if (fetchError != null) {
    doms = <div>{fetchError.message}</div>;
  } else if (testList == null) {
    doms = (
      <div className="test-list">
        <TestItemSkeleton />
        <TestItemSkeleton />
        <TestItemSkeleton />
      </div>
    );
  } else if (testList.length === 0) {
    doms = (
      <div className="test-list-empty">
        <div className="test-list-empty-content">
        <div className="test-list-empty-icon">
        <Lottie animationData={emptyLottieData} style={{'maxWidth': '284px'}} />
        </div>
        <div className="test-list-empty-description">진행가능한 시험이 없습니다.</div>
        </div>
      </div>
    );
  } else {
    doms = (
      <div className="test-list">
        {
          testList.map((test) => {
            const testId = test.id
            return (
              <div key={testId} className="test-item" >
                <TestItem test={test} onStart={() => {
                  setCurrentTest(test)
                }} onContinue={() => {
                  navigate(`/tests/${testId}`)
                }} />
            </div>
          )})
        }
      <BottomSheet style={{'maxWidth': '568px'}} onDismiss={() => {
          setCurrentTest(null)
      }} open={currentTest != null}>
          {currentTest == null ? <div></div>:
            <div className="test-confirm-bottom-sheet">
              <div className="test-confirm-bottom-sheet-content">
                <div className="test-confirm-bottom-sheet-title">시험을 시작하시겠습니까?</div>
                <div className="test-confirm-bottom-sheet-description">
                  {
                    "시험을 시작하면 제한시간내에 제출해야합니다.\n" +
                    "제한시간이 지나면 자동으로 제출됩니다."
                  }
                </div>
                <div className="test-confirm-bottom-sheet-icon">
                  <Lottie animationData={confirmLottieData} style={{width: "50%", 'maxWidth': '284px'}} />
                </div>
                <div className="test-confirm-bottom-sheet-bottom-description">
                  {
                    "문항수 " + currentTest.question_sheet.number_of_questions + ", 제한시간 " + (Math.round(currentTest.question_sheet.time_limit * 100 / 60) / 100) + "분"
                  }
                </div>
              </div>
              <div className="test-confirm-bottom-sheet-button ripple-key-color" onClick={() => { navigate(`/tests/${currentTest.id}`) }}>
                  시작하기
              </div>
            </div>
          }
          
      </BottomSheet>
      </div>
    );
  }
  return <div>
    <div className='user-info'>
      <div className='user-info-name'>
        {loggedIn.user.username} | {loggedIn.user.nickname}
      </div>
    </div>
    {doms}
  </div>;
}

export default TestList;

