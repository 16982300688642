import './App.css';
import React from 'react';
import { RecoilRoot } from 'recoil';
import Root from './Root/Root';

function App() {
  return (
    <RecoilRoot>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Root />
      </React.Suspense>
    </RecoilRoot>
  );
}

export default App;
