import './TestComplete.css';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { navigationBarState } from '../../../states/NavigationBar';
import Lottie from "lottie-react";
import completeLottieData from './complete.json'

function TestComplete({ title, description, onConfirmButtonClick }) {
    const setNavigationBarState = useSetRecoilState(navigationBarState)    

    useEffect(() => {
        setNavigationBarState(null);
    }, []);

    return <div>
        <div className="test-complete-container">
            <div className="test-complete-information">
                <Lottie className="test-complete-logo" animationData={completeLottieData} />
                <div className="test-complete-title">{title}</div>
                <div className="test-complete-description">{description}</div>
            </div>
            <div className="test-complete-confirm-button ripple-key-color" onClick={() => { onConfirmButtonClick() }}>
                확인
            </div>
        </div>
    </div>
}

export default TestComplete;