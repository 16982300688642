import { selector } from 'recoil';
import axios from 'axios';
import { accessTokenState } from './Token';

export const LoggedInState = selector({
  key: 'loggedIn',
  get: ({ get }) => {
    const accessToken = get(accessTokenState);

    if (accessToken == null) {
      axios.defaults.headers.common.Authorization = null;
      return null;
    }

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken.access}`;
    return { user: accessToken.user };
  },
});
