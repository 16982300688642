import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from "axios";
import App from './components/App';
import './index.css';

// axios
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL ?? "https://localhost:8443";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <App />
  // </React.StrictMode>
);
