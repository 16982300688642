
import React from 'react';
import { useRecoilValue } from 'recoil';
import { LoggedInState } from '../../states/LoggedIn';
import CustomToaster from '../Common/Toaster/CustomToaster';
import LoggedOut from '../LoggedOut/LoggedOut';
import LoggedIn from '../LoggedIn/LoggedIn';

function Root() {
  const loggedIn = useRecoilValue(LoggedInState)

  if (loggedIn != null) {
    if (loggedIn.user.type != "S") {
      window.location.href = process.env.REACT_APP_ADMIN_URL ?? 'https://localhost:3031/admin'
      return
    }
  }

  return <div>
    <CustomToaster />
    {loggedIn == null ? <LoggedOut /> : <LoggedIn />}
  </div>
}

export default Root;