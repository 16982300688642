import './TestMain.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TestQuestion from '../Question/TestQuestion'
import TestTimeRemained from '../TimeRemained/TestTimeRemained'
import { useSetRecoilState } from 'recoil';
import { navigationBarState } from '../../../../states/NavigationBar';
import AlertDialog, { AlertActionButton } from '../../../Common/Alert/Alert';

function TestMain({ testId, testData, onTestEnded, onTestSubmit }) {
    const setNavigationBarState = useSetRecoilState(navigationBarState)    
    
    let initialAnswersQuestion = {}
    if (testData.answer_sheet != null) {
      testData.answer_sheet.answers.forEach(value => {
        initialAnswersQuestion[value.question] = value
      })
    }
    const [remainTimeOnNavBar, setRemainTimeOnNavBar] = useState(false)
    const [answerQuestions, setAnswerQuestions] = useState(initialAnswersQuestion)
    const [numberOfAnswers, setNumberOfAnswers] = useState(Object.keys(initialAnswersQuestion).length)
    const [numberOfQuestions, setNumberOfQuestions] = useState(testData.question_sheet.questions.length)
    const [startTime, setStartTime] = useState(new Date(testData.answer_sheet.created_at))
    const [limitTime, setLimitTime] = useState(testData.question_sheet.time_limit)
    const [currentTime, setCurrentTime] = useState(new Date(testData.current_time))
    const [localTime, setLocalTime] = useState(new Date())
    const [showsSumitAlertDialog, setShowsSumitAlertDialog] = useState(false)
    const onSubmit = (callback) => {
      axios.post("/tests/" + testId + "/submit").then(response => {  
        callback(response.data)
      }).catch(error => {
         // Toast 오류  
      });
    }

      useEffect(() => {
        function handleScroll() {
          setRemainTimeOnNavBar(window.scrollY > 80)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      }, [])

      useEffect(() => {
          let status = numberOfQuestions > 0 ? (numberOfAnswers + " of " + numberOfQuestions) : null
          let timeRemainedDom = <TestTimeRemained startTime={startTime} currentTime={calculateCurrentTime()} limitTime={limitTime} onEnded={onTestEnded} />
          setNavigationBarState({
            "title": remainTimeOnNavBar ? timeRemainedDom : "Exam",
            "left-item": { 
              "type" : "back", 
              "dialog": {
                "title": "시험을 종료하시겠습니까?",
                "description": "남은 시험시간내에는 이어서 진행할 수 있습니다.",
                "button-title-back": "종료",
                "button-title-cancel": "취소"
              }
            },
            "right-item": { "type" : "text", "value": status },
          })
      }, [numberOfAnswers, remainTimeOnNavBar])

    function onSubmitAnswer(question, answer) {
      let changedAnswerQuestions = answerQuestions
      changedAnswerQuestions[question.id] = answer
      setAnswerQuestions(changedAnswerQuestions)
      setNumberOfAnswers(Object.keys(changedAnswerQuestions).length)
    }

    function onSubmitTest() {
      onSubmit((response) => {
        onTestSubmit(response)
      })
    }

    function calculateCurrentTime() {
      if (testData.answer_sheet.is_editable && (testData.answer_sheet.is_submitted || testData.answer_sheet.is_ended)) {
        return null
      }
      if (localTime == null || currentTime == null) {
        return null
      }
      let diffTime = (new Date() - localTime)
      return new Date(currentTime.getTime() + diffTime)
    }

    return <div className="test-area">
          <div>
            <div className="test-header">
              <TestTimeRemained startTime={startTime} currentTime={calculateCurrentTime()} limitTime={limitTime} onEnded={onTestEnded} />
            </div>
            <div className="test-questions-list">
            {
                testData == null ? <div></div> :
                <div>{
                  testData.question_sheet.questions.map((value, index) => {
                    return (<TestQuestion key={value.question.id} index={index + 1} testId={testId} score={value.score} question={value.question} answer={answerQuestions[value.question.id]} onSubmitAnswer={onSubmitAnswer} />)
                  })
                }
                </div>
            }
            </div>
            <div className="test-submit ripple-key-color" onClick={() => { setShowsSumitAlertDialog(true) }}>
                Submit
            </div>
          </div>
          <div>
            <AlertDialog open={showsSumitAlertDialog} 
              title={numberOfAnswers >= numberOfQuestions ? "시험을 제출하시겠습니까?" : "아직 " + (numberOfQuestions - numberOfAnswers) + "개의 문제의 답이\n제출되지 않았습니다.\n그래도 시험을 제출하시겠습니까?"}
              description={testData.answer_sheet.is_editable === false ? "시험을 제출한뒤에는 답안을 수정할 수 없습니다." : ""}
              buttons={[
              { "title": "취소", "action": () => {
                  setShowsSumitAlertDialog(false)
              }},
              { "title": "제출", "type": AlertActionButton.KEYCOLOR, "action": async () => {
                  setShowsSumitAlertDialog(false)
                  onSubmitTest()
            }} ]} />
          </div>
    </div>
}

export default TestMain;